jQuery(document).ready(function($) {

    var pickupTimeInput = document.getElementById("pickup-time");
    var dropoffTimeInput = document.getElementById("dropoff-time");
    if (pickupTimeInput) pickupTimeInput.value = "8:30";
    if (dropoffTimeInput) dropoffTimeInput.value = "9:00";

    /* Single Product Hire Type + Date Picker functionality */
    var pickupDateLabel = $("dt.variation-PickupDate");
    var dropoffDateLabel = $("dt.variation-DropoffDate");
    //*************************************** */
    //**** START - CART Change Labels ******* */
    //*************************************** */
    pickupDateLabel.text("Start:");
    dropoffDateLabel.text("Finish:");

    // Call the updateText function periodically (e.g., every second)

    //*************************************** */
    //**** END - CART Change Labels ********* */
    //*************************************** */

    /* Initial Setup */
    var hireType = "short";
    var shortHireCostMultiply = 3; //NOTE Update this field to change the multiply price x (?val) for short hire
    var longHireCostMultiply = 1.5; //NOTE Update this field to change the multiply short hire total price x (?val) for long hire
    var pickupDateField = $('#pickup-date');
    var dropoffDateField = $('#dropoff-date');
    var startDateDisplayField = $('.start-date-display-field');
    var finishDateDisplayField = $('.finish-date-display-field');
    var selectedDate;
    var dropoffDate;
    var selectedSize;
    pickupDateField.prop('disabled', true);

    var wcPrice = $('.pm-product-single .woocommerce-Price-amount');
    var ogPriceNum = $(wcPrice).find('bdi').text();
    ogPriceNum = ogPriceNum.replace('$', '');
    priceNum = ogPriceNum * shortHireCostMultiply;
    console.log(priceNum);
    wcPrice.find('bdi').text('$' + Math.ceil(priceNum));
    pickupDateField.attr("placeholder", "Select Date");

    var deliveryDateSelector = $('.delivery-date-selector');
    var productDetails = $('.product-details');

    let isHireCompleted = false;
    let isSizeCompleted = false;
    const completeFirstStep = (hireTypeCompleted, sizeSelectionCompleted) => {
      if (hireTypeCompleted != null) isHireCompleted = hireTypeCompleted;
      if (sizeSelectionCompleted != null) isSizeCompleted = sizeSelectionCompleted;
      if (isHireCompleted === true && isSizeCompleted === true){
        deliveryDateSelector.addClass('date-selector-open');
      }
    }

    // Function to handle option click and update active class
    function handleOptionClick() {
      var sizeValue = $(this).data('size');
      selectedSize = sizeValue;
      $('.hidden-selected-size-input').val(selectedSize);
        // Remove the 'active' class from all elements
        $('.size-option').removeClass('active-size');

        // Add the 'active' class to the element with the matching data-size
        $('.size-option').each(function() {
            if ($(this).data('size') === selectedSize) {
                $(this).addClass('active-size');
                completeFirstStep(null, true);
            }
        });
      }

    // Attach the click event handler to size options
    $('.size-option').on('click', handleOptionClick);

    // Check if there is only one .size-option
    if ($('.size-option').length === 1) {
        console.log('only one!');
        completeFirstStep(null, true);
        // Automatically select the single option
        var singleOption = $('.size-option');
        selectedSize = singleOption.data('size');
        $('.hidden-selected-size-input').val(selectedSize);
        // Add the 'active-size' class to the single option
        singleOption.addClass('active-size');
    }

    $('.hire-type-select').on('change', function() {
        hireType = $(this).val();
        if (hireType === "long"){
            inBetweenDatesUpdate("long");
        } else {
            inBetweenDatesUpdate("short");
        }
        pickupDateField.prop('disabled', false);
        calculateDropoffDate();
        updatePrice();
        pickupDateField.trigger('change'); // Trigger the change event
        completeFirstStep(true, null);
    });

    pickupDateField.on('change', function() {
        selectedDate = $(this).val();
        calculateDropoffDate();
        updatePrice();
        // console.log("Selected date: " + selectedDate);
        // console.log("Dropoff date: " + dropoffDate);
        if (selectedDate && dropoffDate){
            startDateDisplayField.text(selectedDate);
            finishDateDisplayField.text(dropoffDate);
            productDetails.addClass('product-details-open');
        }

    });

    const calculateDropoffDate = () => {
        // console.log(hireType);
        var pickupDate = new Date(selectedDate); // Your initial date
        console.log(hireType);
        var hireDuration = 3;
        if (hireType === "long") hireDuration = 7;
        pickupDate.setDate(pickupDate.getDate() + hireDuration);
        dropoffDate = formatDate(pickupDate);
        if (selectedDate){
            pickupDateField.val(selectedDate);
            dropoffDateField.val(dropoffDate);
        }
        // return dropoffDate;
    }
    function formatDate(date) {
        const parsedDate = new Date(date);

        // Check if the parsedDate is a valid date
        if (isNaN(parsedDate.getTime())) {
            return "Invalid Date Format";
        }

        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const year = parsedDate.getFullYear();
        return `${month}/${day}/${year}`;
    }

    function updatePrice(){
        ogPriceNum = ogPriceNum.replace('$', '');
        priceNum = ogPriceNum * shortHireCostMultiply;
        console.log('Updating Price!');
        console.log('HireType: ' + hireType);
        console.log('priceNum: ' + priceNum);
        if (hireType === "long"){
            priceNum = priceNum * longHireCostMultiply;
            console.log('Added priceNum: ' + priceNum);
        }
        // console.log(priceNum);
        wcPrice.find('bdi').text('$' + Math.ceil(priceNum));
    }


    //* Request size overlay form functionality
    let formOverlay = $('.form-overlay');
    $('.request-size-btn').on('click', () => {
        formOverlay.toggleClass('hide-overlay');
        var inputElement = $('input.wpcf7-form-control.wpcf7-hidden[name="requested_product"]');
        var inputElementUrl = $('input.wpcf7-form-control.wpcf7-hidden[name="requested_product_url"]');
        // Change the value of the input
        var hiddenProductName = $('.hidden-product-name');
        // Grab the data-productname attribute value
        var productname = hiddenProductName.data('productname');
        var producturl = hiddenProductName.data('producturl');
        inputElement.val(productname);
        inputElementUrl.val(producturl);
    });
    $('.close-request-form').on('click', () => {
        formOverlay.toggleClass('hide-overlay');
    });


    // formOverlay.on('click', (e) => {
    //     console.log(e);
    //     formOverlay.toggleClass('hide-overlay');
    // });
    if ($('body').hasClass('single-product')) {
        console.log('IS Single Product');
        // Select the element you want to watch for changes
        var totalCostEl = $('.booking_cost span')[0]; // Get the first matching element
        // totalCostEl.on('click', function(){
        //     console.log('test');
        // })
        // Create a new MutationObserver
        var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.type === 'childList') {
            // Content inside the .booking_cost span has changed
            var newContent = $(totalCostEl).text();
            var actualPrice = $(wcPrice).find('bdi').text();
            console.log("newContent: " + newContent);
            console.log("actualPrice: " + actualPrice);
            if (newContent !== actualPrice){
                console.log('Content changed: ' + newContent);
                console.log(actualPrice);
                $(totalCostEl).text(actualPrice);
            }
            }
        });
        });
        // Configure and start the observer
        var config = { childList: true, characterData: true, subtree: true };
        observer.observe(totalCostEl, config);
    }


    $('.cart .date-time-picker h5').text('Delivery or collection date');

    //Custom button made so can be placed elsewhere on single product. View custom-rental-add-to-cart.php
    $('.custom-add-to-cart-btn').on('click', function(e) {
        e.preventDefault();
        $('.hide-main-add-to-cart-btn').trigger('click');
    });

    //Custom field made so can be placed elsewhere on single product. View custom-rental-add-to-cart.php
    $('.event-date-input').on('input', function(e) {
        // e.preventDefault();
        var inputValue = $(this).val();
        // Update the hidden input with the same value
        $('.hidden-event-date-input').val(inputValue);
    });

    let termsChecked = false;
    let idUploadedCompleted = false;
    let attachmentId = null;
    const updateRequiredFields = (termsVal, idUploadVal) => {
      if (termsVal !== null) termsChecked = termsVal;
      if (idUploadVal !== null) idUploadedCompleted = idUploadVal;

      if (termsChecked === true && idUploadedCompleted === true){
        $('.wc-proceed-to-checkout a').removeClass('disabled-btn');
      } else {
        $('.wc-proceed-to-checkout a').addClass('disabled-btn');
      }
    }

    $('.wc-proceed-to-checkout a').addClass('disabled-btn');
    $('#agreeCheckboxCart').on('change', function() {
        if ($(this).is(':checked')) {
          updateRequiredFields(true, null);
        } else {
          updateRequiredFields(false, null);
        }
    });

    const inBetweenDatesUpdate = (hireType = "long") => {

        //TODO
        // Grab both data arrays.
        // Use if condition to remove array items from the plugin dates of the previous array
        // Then add in the other array items
        // Reverse for the other option.

        const inBetweenDatesShortHireDaysArray = $('#inBetweenDatesArrayShortHireDay');
        const inBetweenDatesShortHireDaysArrayData = inBetweenDatesShortHireDaysArray.data('inbetweendatesarrayshorthireday');
        const inBetweenDateLongHireDaysArray = $('#inBetweenDatesArrayLongHireDay');
        const inBetweenDatesLongHireArrayData = inBetweenDateLongHireDaysArray.data('inbetweendatesarraylonghireday');

        var pluginDates = BOOKING_DATA.block_dates;
        // console.log(BOOKING_DATA.block_dates);

        // console.log("inBetweenDatesShortHireDaysArrayData");
        // console.log(inBetweenDatesShortHireDaysArrayData);
        // console.log("inBetweenDatesLongHireArrayData");
        // console.log(inBetweenDatesLongHireArrayData);
        // console.log("pluginDates");
        // console.log(pluginDates);
        // Use filter to create a new array without the dates present in either array
        var filteredBlockDates = pluginDates.filter(function (date) {
            return !inBetweenDatesShortHireDaysArrayData.includes(date) || !inBetweenDatesLongHireArrayData.includes(date);
        });

        // Use both arrays to wipe out any blocked dates from BOOKING_DATA
        // then add back in the array that is needed.
        if (hireType == "long") {
            console.log("[Long Hire]");
            filteredBlockDates = filteredBlockDates.concat(inBetweenDatesLongHireArrayData);
        } else {
            console.log("[Short Hire]");
            filteredBlockDates = filteredBlockDates.concat(inBetweenDatesShortHireDaysArrayData);
        }
        console.log("filteredBlockDates");
        console.log(filteredBlockDates);

        // mergedDates = mergedDates.concat(additionalDisabledDates);

        $('#pickup-date').datetimepicker({
            timepicker: false,
            scrollMonth: false,
            format: 'm/d/Y',
            minDate: 0,
            disabledDates: [],
            formatDate: 'm/d/Y',
            onShow: function (ct) {
                this.setOptions({
                    disabledDates: [], // Reset disabled dates to an empty array
                });
            },
        });
        console.log('DATEPICKER UPDATE');
        //WORKS I NEED TO MERGE THE ARRAY INTO THE EXISTING DISABLED DATES THO.
        $('#pickup-date').datetimepicker({
            timepicker:false,
            scrollMonth: false,
            format:'m/d/Y',
            minDate: 0,
            disabledDates: filteredBlockDates,
            formatDate: 'm/d/Y',
            onShow:function( ct ){
              this.setOptions({
                disabledDates: filteredBlockDates,
            })
          },
        });
        // existingDatetimepicker.datetimepicker('setOptions', {disabledDates: ['21.01.2024', '22.01.2024', '23.01.2024'], formatDate:'d.m.Y' });

    }
    // inBetweenDatesUpdate();


    // Upload ID
    $('#idUploadInput').on('change', function(e) {
      console.log('[idUploadInput]');
      let file = this.files[0];  // Get the selected file
      let formData = new FormData();  // Create FormData object
      formData.append('file', file);  // Append the file to the FormData object
      console.log(file);

       // Make the AJAX request to the custom endpoint
      $.ajax({
        url: '/wp-admin/admin-ajax.php?action=anonymous_file_upload',  // Custom endpoint
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false,
        success: function(response) {
            if (response.success) {
                console.log(response);
                let fileUrl = response.data.file_url;  // Get the file URL
                attachmentId = response.data.attachment_id;  // Get the file URL
                console.log("File uploaded successfully: " + fileUrl);
                displayFileInUploader(file);
                updateRequiredFields(null, true);

            } else {
                console.error('Upload error:', response.data.error);
            }
        },
        error: function(xhr, status, error) {
            console.error('Upload error:', xhr.responseText);
        }
    });

    const displayNameElement = $('#display-id-filename');
    const fileElement = $('.id-file-element');

    const displayFileInUploader = (file) => {
      displayNameElement.text(file.name);
      fileElement.removeClass('hide-id-file');
    }

    $('.remove-uploaded-file').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      displayNameElement.text("");
      fileElement.addClass('hide-id-file');
      updateRequiredFields(null, false);

      $.ajax({
        url: '/wp-admin/admin-ajax.php?action=delete_uploaded_file',
        method: 'POST',
        data: {
            attachment_id: attachmentId  // Pass the attachment ID
        },
        success: function(response) {
            if (response.success) {
                console.log("File deleted successfully.");
                // Remove the file from the UI or display a success message
            } else {
                console.log("Failed to delete file: " + response.data);
            }
        },
        error: function(xhr, status, error) {
            console.log("AJAX error: " + error);
        }
    });
    });


    });

});
