import "./single-product";

jQuery(document).ready(function($) {
    console.log('[main.js]');
    /* Mobile menu */
    var mobMenuContainer = $('.mobile-menu-container');
    $(".menu-icon").click(function() {
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
            mobMenuContainer.removeClass("open");
        } else {
            $(this).addClass("open");
            mobMenuContainer.addClass("open");
        }
    });

    //* Single Product Accordion
    $(".accordion-title").click(function() {
        var content = $(this).next(".accordion-content");
        var plusSign = $(this).find(".plus-sign");
        if (content.hasClass("active")) {
            content.css("max-height", 0);
            content.removeClass("active");
            plusSign.removeClass("active");
        } else {
            content.css("max-height", (content.prop("scrollHeight") + 100) + "px");
            content.addClass("active");
            plusSign.addClass("active");
        }
    });



    //* ADMIN FUNCTIONALITY
    var generalPriceInput = $('#general_price');

    // Modify the step attribute to allow decimal values
    generalPriceInput.attr('step', '0.01');
});